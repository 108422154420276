const authCheck = () => {
  const loginDatas = localStorage.getItem('@darul-ifta-user-login-details');
  if (loginDatas) {
    const jsonData = JSON.parse(loginDatas);
    if (jsonData?.user_type === 'User') {
      return { isUser: true, isAdmin: false };
    }
    if (jsonData?.user_type === 'Super Admin') {
      return { isUser: false, isAdmin: true };
    }
  } else {
    return { isUser: false, isAdmin: false };
  }
};

const authLogout = (cb) => {
  localStorage.removeItem('@darul-ifta-user-login-details');
  if (cb) cb();
};

export { authCheck, authLogout };
