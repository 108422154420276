import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone';
import { toast } from 'react-toastify';
import { addUserLoginDetails } from '../../../redux/actions';
import { StoreLocal } from '../../../utils/localStore';
import { URLS } from '../../../config/urls.config';
// import Profile from "../../../assets/Minaret.svg";
// import { URLS } from "../../../config/urls.config";
// import Loader from "../../../components/common/Loader";
// import SnackBar from "../../../components/common/Snackbar";
// import { Typography } from "@mui/material";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import './admin.profile.styles.scss';

function AdminProfile({ close, userLoginDetails, addUserLoginDetails }) {
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState(null);
  const [madbahList, setMadbahList] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [country, setCountry] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({});

  useEffect(() => {
    setUserDetails(userLoginDetails);
    console.log('logggin', userLoginDetails);
    setValue('displayName', userLoginDetails?.display_title);
    setValue('name', userLoginDetails?.name);
    setValue('email', userLoginDetails?.email);
    setValue('phoneNumber', userLoginDetails?.phone);
    // setValue("address", userLoginDetails?.address);

    // setValue("country", userLoginDetails?.country.countryNameEn);
    // setCountryName(JSON.parse(userLoginDetails?.country))
  }, []);

  const handleUserUpdate = ({ displayName, name, email, phoneNumber }) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('display_title', displayName);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phoneNumber);
    formData.append('user_type', userLoginDetails?.user_type);
    formData.append('user_status', userLoginDetails?.user_status);
    axios
      .put(`${URLS.user}${URLS.signup}/${userLoginDetails._id}`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then((res) => {
        if (res.success) {
          toast(res.message, {
            onClose: () => {
              setLoader(false);
              addUserLoginDetails(res.data);
            }
          });
        } else {
          toast(res.message, {
            onClose: () => {
              setLoader(false);
            }
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        toast('Something went wrong, please try again later');
        // toast("Somthing went wrong, please try again later", {
        //   onClose: () => {
        //     setLoader(false);
        //   },
        // });
        console.error('Error in profile edit', err);
      });
  };
  return (
    <div className="admin-profile-update shadow bg-white">
      <form onSubmit={handleSubmit(handleUserUpdate)}>
        <div className="admin-profile-container">
          <div className="row">
            <div className="col-md-3">
              {/* <div className="image-wrapper">
                  <img
                    src={Profile}
                    width="250"
                    height="250"
                    alt="profile Image"
                  />
                </div> */}
              {/* <div className="row d-flex justify-content-around">
                  <div className="col-md-5">
                    <Typography>Profile Name</Typography>
                  </div>
                  <div className="profile-remove col-md-2">
                    <Typography>
                      <DeleteOutlineOutlinedIcon className="delete-icon" />
                    </Typography>
                  </div>
                </div> */}
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    size="small"
                    label="Display Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: userDetails?.display_title }}
                    {...register('displayName', { required: 'Display Name is required' })}
                  />
                  <div className="error">{errors?.displayName?.message}</div>
                  {/* </div> */}
                  {/* <div className="col-md-6"> */}
                  <br />
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    size="small"
                    label="Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: userDetails?.name }}
                    {...register('name', { required: 'Name is required' })}
                  />
                  <div className="error">{errors?.firstName?.message}</div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-6">
                  {/* <TextField
                      id="outlined-basic"
                      fullWidth
                      size="small"
                      label="Last Name"
                      variant="outlined"
                      {...register("lastName", { required: "Last Name is required" })}
                    />
                    <div className="error">{errors?.lastName?.message}</div>
                  </div>
                  <div className="col-md-6"> */}
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    size="small"
                    label="Email"
                    variant="outlined"
                    InputLabelProps={{ shrink: userDetails?.email }}
                    disabled
                    {...register('email', {
                      required: 'Email ID is required',
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Invalid email Id ( eg: example@mail.com ) '
                      }
                    })}
                  />
                  <div className="error">{errors?.email?.message}</div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-6">
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    size="small"
                    type="number"
                    label="Phone Number"
                    variant="outlined"
                    InputLabelProps={{ shrink: userDetails?.phone }}
                    {...register('phoneNumber', {
                      required: 'Phone Number is required',
                      minLength: {
                        value: 10,
                        message: 'Phone Number length must be 10 digit. '
                      },
                      maxLength: {
                        value: 10,
                        message: 'Phone Number length must be 10 digit. '
                      }
                    })}
                  />
                  <div className="error">{errors?.phoneNumber?.message}</div>
                  <br />
                  <div className="d-flex justify-content-end">
                    <Button variant="contained" className="form-btn" type="submit" fullWidth>
                      Update
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="row mt-4">
                  <div className="col-md-6">
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      size="small"
                      type="password"
                      label="Password"
                      variant="outlined"
                      {...register("password", { required: "Password is required" })}
                    />
                    <div className="error">{errors?.password?.message}</div>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </form>

      {/* {errorPopup.visible && (
            <SnackBar
              visible={errorPopup.visible}
              message={errorPopup.message}
              type={errorPopup.type}
              title={errorPopup.title}
              onClose={() => handleCloseError()}
            />
          )} */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state
});
const mapDispatchToProps = (dispatch) => ({
  addUserLoginDetails: (payload) => dispatch(addUserLoginDetails(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile);
