import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import './admin-login.styles.scss';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField, InputAdornment, IconButton, Button, Grid, Paper } from '@mui/material';
import routerList from '../../../routes/routerList';
import { StoreLocal } from '../../../utils/localStore';
import { addUserLoginDetails } from '../../../redux/actions';
import { URLS } from '../../../config/urls.config';

function AdminLogin(props) {
  const navigate = useNavigate();
  const [screens, setScreens] = useState('email');
  const [isVisible, setVisible] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [imgSrc, setImgsrc] = useState([]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const handleLogin = ({ email, password }) => {
    setLoader(true);
    axios
      .post(
        `${URLS.user}${URLS.login}`,
        { email, password },
        {
          'Content-Type': 'application/json'
        }
      )
      .then((data) => {
        setLoader(false);
        console.log('res login', data);

        if (data.success && data.data) {
          toast(data.message, {
            onClose: () => {
              setLoader(false);
              navigate(`${routerList.admin.admin}`);
            }
          });
          StoreLocal('@darul-ifta-user-login-details', data.data, () => {
            props.addUserLoginDetails(data.data);
            navigate(`${routerList.admin.admin}`);
          });
        } else {
          toast(data.message, {
            onClose: () => {
              setLoader(false);
            }
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        toast('Something went wrong, please try again later', {
          onClose: () => {
            setLoader(false);
          }
        });
        console.log('error login', err);
      });
  };
  return (
    <div className="admin-login" style={{ marginTop: '100px' }}>
      <Container maxWidth="sm">
        <Paper elevation={3}>
          <Box className="d-flex" sx={{ height: '450px' }}>
            <Grid
              container
              spacing={2}
              mb={2}
              className="d-flex justify-content-center align-content-center"
            >
              <Grid item xs={8} className="d-flex justify-content-center">
                <LockOpenIcon className="lock-icon" />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4" align="center" className="heading-section" gutterBottom>
                  Admin Panel
                </Typography>
              </Grid>
              <>
                <form onSubmit={handleSubmit(handleLogin)}>
                  <Grid item xs={8}>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      size="small"
                      label="Email"
                      variant="outlined"
                      className="email"
                      style={{ width: '300px' }}
                      {...register('email', {
                        required: 'Email ID is required',
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: 'Invalid email Id ( eg: example@mail.com ) '
                        }
                      })}
                    />
                    <div className="error">{errors?.email?.message}</div>
                  </Grid>
                  <br />
                  <Grid item xs={8}>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      size="small"
                      label="Password"
                      variant="outlined"
                      className="password"
                      style={{ width: '300px' }}
                      type={isVisible ? 'text' : 'password'}
                      {...register('password', {
                        required: 'Password is required',
                        minLength: {
                          value: 8,
                          message: 'Minimum 8 character'
                        }
                      })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setVisible(!isVisible)} edge="end">
                              {!isVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <div className="error">{errors?.password?.message}</div>
                  </Grid>
                  <Grid align="center" mt={2} item xs={8}>
                    <Button
                      className="login-button"
                      fullWidth
                      variant="contained"
                      type="submit"
                      style={{ width: '300px' }}
                    >
                      Login
                    </Button>
                  </Grid>
                </form>
              </>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </div>
  );
}
const mapStateToProps = (state) => ({
  ...state
});
const mapDispatchToProps = (dispatch) => ({
  addUserLoginDetails: (payload) => dispatch(addUserLoginDetails(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
