import React from 'react';
import FatwaSection from '../../../components/FatwaSection';
import FooterComponent from '../../../components/Footer';

export default function Fatwas() {
  return (
    <div>
      <FatwaSection />
      <FooterComponent />
    </div>
  );
}
