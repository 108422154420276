import axios from 'axios';
import { getLocal } from '../utils/localStore';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// axios.defaults.headers.common.Authorization =
//   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InZhbWlrYXZpdmVrMzhAZ21haWwuY29tIiwiaWF0IjoxNjYzNzM0OTg5LCJleHAiOjE2ODk2NTQ5ODl9.LmtTFhX6iVWtaVYkrwrQ5Ao9hWwCwAfUZT_KM_j-wVo';
// axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.request.use((req) => {
  console.log("Reqqqq ---- ",req);

  
  getLocal().then(res => {
      if (res?.initial_token) {
        req.headers['Authorization'] = `Bearer ${res?.initial_token}`;
      }
    })


  if (req?.headers['Content-Type']) {
    req.headers['Content-Type'] = req.headers['Content-Type'];
    req.headers.common['Content-Type'] = req.headers['Content-Type'];
  } else {
    req.headers['Content-Type'] = 'application/json';
    req.headers.common['Content-Type'] = 'application/json';
  }

  return req;
});

axios.interceptors.response.use((res) => res?.data);

const useAxios = () => {
  // url, payload
  //   const [data, setData] = useState(null);
  //   const [error, setError] = useState("");
  //   const [loaded, setLoaded] = useState(true);
  //   const URL = `${process.env.BASE_URL}${url}`;
  //   useEffect(() => {
  //     axios
  //       .post(url, payload)
  //       .then((res) => setData(res.data))
  //       .catch((error) => setError(error.message))
  //       .finally(() => setLoaded(false));
  //   }, []);
  // { data, error, loaded };
};

export default useAxios;
