import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { Avatar, Menu, MenuItem, ListItemIcon, IconButton } from '@mui/material';

import HouseIcon from '@mui/icons-material/House';
import PersonIcon from '@mui/icons-material/Person';

import Logout from '@mui/icons-material/Logout';
import { authLogout } from '../routes/auth';
import routerList from '../routes/routerList';
import LogoImage from '../assets/logo-main-logo-blue.svg';

import '../styles/header.styles.scss';

function HeaderComponent({ closePopup, userLoginDetails }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const navigate = useNavigate();
  const location = useLocation();
  const handleLogin = async () => {
    if (userLoginDetails) {
      navigate(`${routerList.user.accountUser}`);
    } else {
      navigate(`${routerList.user.login}`);
    }
  };

  const [visible, setVisible] = useState(false);

  return (
    <div
      className={
        location?.pathname === '/' ? 'navbar-section' : 'header-border navbar-section shadow-sm'
      }
    >
      <a href="https://wa.me/+919633712592" class="whatsapp-link" target="_blank">
        <marquee width="100%" direction="left" height="auto" onmouseover="this.stop();" onmouseout="this.start();" style={{ backgroundColor: "#235775", color: '#fff' }}>
          It's a <span style={{ color: "#FFD700" }}> Beta release </span>. If you face any issues, please contact us on <span >WhatsApp</span> forum by <span style={{ color: "#00cbb3" }}>Click here</span>.
        </marquee>

      </a>
      <div className="container">
        <Navbar
          bg="light"
          className="p-0"
          expand="lg"
          onClick={() => setVisible(!visible)}
          expanded={visible}
        >
          <Container className="navbar-wrapper-main p-0">
            <Navbar.Brand onClick={() => navigate(`${routerList.user.home}`)}>
              <img className="img-thumnails img-logo" src={LogoImage} alt="logo image" />
            </Navbar.Brand>
            <Navbar.Toggle onClick={() => setVisible(!visible)} />
            <Navbar.Collapse>
              <Nav className="">
                <ul className="navbar-nav mb-lg-0">
                  <li
                    className="nav-item"
                    onClick={() => {
                      setVisible(!visible);
                      navigate(`${routerList.user.home}`);
                    }}
                  >
                    <a className="nav-link active custom-menu" aria-current="page">
                      <HouseIcon />
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => navigate(`${routerList.user.accountAskQuestion}`)}
                  >
                    <a className="nav-link custom-menu" aria-current="page">
                      Ask Question
                    </a>
                  </li>

                  <li className="nav-item" onClick={() => navigate(`${routerList.user.fatwas}`)}>
                    <a className="nav-link custom-menu" aria-current="page">
                      Latest Fatwa
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => navigate(`${routerList.user.about}`)}>
                    <a className="nav-link custom-menu" aria-current="page">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link custom-menu"
                      aria-current="page"
                      href="https://rzp.io/l/7JpsyOxuZ"
                    >
                      Donation
                    </a>
                  </li>
                  <li className="nav-item nav-profile-section" dropdown>
                    {userLoginDetails ? (
                      <>
                        <IconButton
                          className="profile-icon p-0"
                          onClick={handleClick}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                        >
                          <span
                            style={{
                              fontWeight: 500,
                              color: '#5e8399',
                              fontSize: '12px',
                              marginRight: '10px',
                              borderRadius: '5px',
                              boxShadow: '0px 0px 3px 1px #cacaca',
                              padding: '6px'
                            }}
                            onClick={() => navigate(`${routerList.user.accountUser}`)}
                          >
                            {userLoginDetails?.name}
                          </span>
                          <Avatar
                            src={userLoginDetails?.profile_pic}
                            sx={{ width: 32, height: 32 }}
                          >
                            {userLoginDetails?.name?.charAt(0)}
                          </Avatar>
                        </IconButton>

                        <span
                          style={{
                            fontWeight: 500,
                            color: '#5e8399',
                            fontSize: '12px',
                            marginRight: '12px',
                            borderRadius: '5px',
                            boxShadow: '0px 0px 3px 1px #cacaca',
                            padding: '6px',
                            marginLeft: '5px'
                          }}
                          onClick={() => {
                            authLogout(() => {
                              navigate(`${routerList.user.home}`);
                              setTimeout(() => {
                                toast('logged out sucessfully');
                                window.reload();
                              }, 100);
                            });
                          }}
                        >
                          Logout
                        </span>
                      </>
                    ) : (
                      <div
                        className="d-flex  align-items-center custom-menu px-3"
                        onClick={() => handleLogin()}
                      >
                        <PersonIcon />
                      </div>
                    )}
                  </li>
                </ul>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps)(HeaderComponent);
