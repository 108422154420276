import { Container, Divider, Grid, Paper, Typography } from '@mui/material';
import FooterComponent from '../../../components/Footer';
import './privacypolicy.styles.scss';

export default function PrivacyAndPolicy() {
  return (
    <div>
      <Container className="privacy-policy-section">
        <Grid spacing={3}>
          <Grid item md={12}>
            <Typography variant="h5" align="center" className="text-decoration-underline">
              Privacy Policy Darul Ifta Kauzariyya
            </Typography>
          </Grid>
          <Grid item md={12} marginTop={1}>
            <Typography variant="subtitle2" align="center" className="fw-bold">
              Updated at 2022-01-01
            </Typography>
          </Grid>
          <Grid item md={12} marginTop={5} marginBottom={3}>
            <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
              <b> DARUL IFTA KAUZARIYYA.</b> ("we," "our," or "us") is committed to protecting your
              privacy. This Privacy Policy explains how your personal information is collected,
              used, and disclosed by DARUL IFTA KAUZARIYYA. This Privacy Policy applies to our{' '}
              <b>website :-</b>
              <a href="">daruliftakauzariyya.com </a>, and its associated subdomains (collectively,
              our "Service") alongside our application, DARUL IFTA KAUZARIYYA. By accessing or using
              our Service, you signify that you have read, understood, and agree to our collection,
              storage, use, and disclosure of your personal information as described in this Privacy
              Policy and our Terms of Service.
            </Typography>
          </Grid>
          <Divider />
        </Grid>
        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Definitions and key terms</Typography>
            </Grid>
            <Grid item md={12} marginTop={1}>
              <Typography variant="paragraph">For this Privacy Policy</Typography>
            </Grid>
            <Grid item md={12} marginTop={4} marginBottom={4} className="listing-section">
              <ul>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Cookie: small amount of data generated by a website and saved by your web
                    browser. It is used to identify your browser, provide analytics, remember
                    information about you such as your language preference or login information.
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Company: when this policy mentions "Company," "we," "us," or "our," it refers to
                    Kauzariyya, Al Jamiathul Kauzariyya, Edathala north po, Aluva, Ernakulam,
                    Kerala, India pin 683561 that is responsible for your information under this
                    Privacy Policy.
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Country: where DARUL IFTA KAUZARIYYA or the owners/founders of DARUL IFTA
                    KAUZARIYYA are based in this case is Kauzariyya.
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Customer: refers to the company, organization or person that signs up to use the
                    DARUL IFTA KAUZARIYYA Service to manage the relationships with your consumers or
                    service users.
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Device: any internet connected device such as a phone, tablet, computer or any
                    other device that can be used to visit DARUL IFTA KAUZARIYYA and use the
                    services.
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    IP address: Every device connected to the Internet is assigned a number known as
                    an Internet protocol (IP) address. These numbers are usually assigned in
                    geographic blocks. An IP address can often be used to identify the location from
                    which a device is connecting to the Internet Personnel: refers to those
                    individuals who are employed by DARUL IFTA KAUZARIYYA or are under contract to
                    perform a service on behalf of one of the parties
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Personal Data: any information that directly, indirectly, or in connection with
                    other information - including a personal identification number - allows for the
                    identification or identifiability of a natural person
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Service: refers to the service provided by DARUL IFTA KAUZARIYYA as described in
                    the relative terms (if available) and on this platform.
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Third-party service: refers to advertisers, contest sponsors, promotional and
                    marketing partners, and others who provide our content or whose products or
                    services we think may interest you.
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Website: DARUL IFTA KAUZARIYYA's site, which can be accessed via this URL:
                    www.daruliftakauzariyya.com
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    You: a person or entity that is registered with DARUL IFTA KAUZARIYYA to use the
                    Services.
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">What Information Do We Collect?</Typography>
            </Grid>
            <Grid item md={12} marginTop={1}>
              <Typography variant="paragraph">
                We collect information from you when you visit our service, register, place an
                order, subscribe to our newsletter, respond to a survey or fill out a form
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={4} marginBottom={4} className="listing-section">
              <ul>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Name
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Phone Numbers (WhatsApp)
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Email Addresses (connected with google or Facebook Only)
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Gender
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Country & State
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Password
                  </Typography>
                </li>
                <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                  <b>NB :</b> You will be able to use our service if you provide us with some of
                  your passional formation.
                </Typography>
              </ul>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">How Do We Use the Information We Collect?</Typography>
            </Grid>
            <Grid item md={12} marginTop={1}>
              <Typography variant="paragraph">
                Any of the information we collect from you may be used in one of the following ways:
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={4} marginBottom={4} className="listing-section">
              <ul>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    To personalize your experience (your information helps us to better respond to
                    your individual needs)
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    To improve our service (we continually strive to improve our service offerings
                    based on the information and feedback we receive from you)
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    To improve customer service your information helps us to more effectively
                    respond to your customer service requests and support needs
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    To process transactions (Donation purpose only)
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    To administer a contest, promotion, survey or other site feature
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    To send periodic emails & WhatsApp messages
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">
                When do we use customer information from third parties?
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                show interest in becoming our customer, we receive information from a third party
                that provides automated fraud detection services to us. We also occasionally collect
                information that is made publicly available on social media websites (like google,
                fb, twitter, LinkedIn). You can control how much of your information social media
                websites make public by visiting these websites and changing your privacy settings.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">
                Do we share the information we collect with third parties?
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={2}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We may share the information that we collect, both personal and non-personal, with
                third parties such as advertisers, contest sponsors, promotional and marketing
                partners, and others who provide our content or whose products or services we think
                may interest you. We may also share it with our current and future affiliated
                companies and business partners, and if we are involved in a merger, asset sale or
                other business reorganization, we may also share or transfer your personal and
                non-personal information to our successors-in-interest We may engage trusted
                third-party service providers to perform functions and provide services to us, such
                as hosting and maintaining our servers and our service, database storage and
                management, e-mail management, storage marketing, credit card processing, customer
                service and fulfilling to provide solutions and remedies to your Islamic doubts
                through our platform.
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={2}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We will likely share your personal information, and possibly some non-personal
                information, with these third parties to enable them to perform these services for
                us and for you
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={2}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We may share portions of our log file data, including IP addresses, for analytics
                purposes with third parties such as web analytics partners, application developers,
                and ad networks. If your IP address is shared, it may be used to estimate general
                location and other technographics such as connection speed, whether you have visited
                the service in a shared location, and type of device used to visit the service.
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We may also disclose personal and non-personal information about you to government
                or law enforcement officials or private parties as we, in our sole discretion,
                believe necessary or appropriate in order to respond to claims, legal process
                (including subpoenas), to protect our rights and interests or those of a third
                party, the safety of the public or any person, to prevent or stop any illegal,
                unethical, or legally actionable activity, or to otherwise comply with applicable
                court orders, laws, rules and regulations.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">
                Where and when is information collected from customers and end users?
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We will collect personal information that you submit to us. We may also receive
                personal information about you from third parties as described above.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">How Do We Use Your Email Address?</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={3}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                By submitting your email address on this both, you agree to receive emails from us.
                You can cancel your participation in any of these email lists at any time by
                clicking on the opt-out link or other unsubscribe option that is included in the
                respective email. We only send emails to people who have authorized us to contact
                them, either directly, or through a third party. We do not send unsolicited
                commercial emails, because we hate spam as much as you do. By submitting your email
                address, you also agree to allow us to use your email address for customer audience
                targeting on sites like Facebook, where we display custom advertising to specific
                people who have opted-in to receive communications from us. Email addresses
                submitted only through the New Account crating page will be used for the sole
                purpose of sending you information and updates pertaining to your Fatwas. If,
                however, you have provided the same email to us through another method, we may use
                it for any of the purposes stated in this Policy.
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                <b>Note:</b> If at any time you would like to unsubscribe from receiving future
                emails, we include detailed unsubscribe instructions at the bottom of each email.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">
                Could my information be transferred to other countries?
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We are (Kauzariyya) Your information will not be transferred to other countries or
                locations in a way that would cause you unnecessary inconvenience.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">
                Is the information collected through our service secure?
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={3}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We take precautions to protect the security of your information. We have physical,
                electronic, and managerial procedures to help safeguard, prevent unauthorized
                access, maintain data security, and correctly use your information. However, neither
                people nor security systems are fool proof, including encryption systems. In
                addition, people can commit intentional crimes, make mistakes or fail to follow
                policies. Therefore, while we use reasonable efforts to protect your personal
                information, we cannot guarantee its absolute security. If applicable law imposes
                any non-disclaim able duty to protect your personal information, you agree that
                intentional misconduct will be the standards used to measure our compliance with
                that duty
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Can I update or correct my information?</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={3}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                The rights you have to request updates or corrections to the information we collect
                depend on your relationship with us. Personnel may update or correct their
                information as detailed in our internal company employment policies. Customers have
                the right to request the restriction of certain uses and disclosures of personally
                identifiable information as follows. You can contact us in order to
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={3}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                (1) update or correct your personally identifiable information,
              </Typography>
              <br />
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                (2) change your preferences with respect to communications and other information you
                receive from us, or
              </Typography>
              <br />
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                (3) delete the personally identifiable information maintained about you on our
                systems subject to the following paragraph, by cancelling your account. Such
                updates, corrections, changes and deletions will have no effect on other information
                that we maintain, or information that we have provided to third parties in
                accordance with this Privacy Policy prior to such update, correction, change or
                deletion.
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                To protect your privacy and security, we may take reasonable steps (such as
                requesting a unique password) to verify your identity before granting you profile
                access or making corrections. You are responsible for maintaining the secrecy of
                your unique password and account information at all times. You should be aware that
                it is not technologically possible to remove each and every record of the
                information you have provided to us from our system. The need to back up our systems
                to protect information from inadvertent loss means that a copy of your information
                may exist in a non-erasable form that will be difficult or impossible for us to
                locate. Promptly after receiving your request, all personal information stored in
                databases we actively use, and other readily searchable media will be updated,
                corrected, changed or deleted, as appropriate, as soon as and to the extent
                reasonably and technically practicable. If you are an end user and wish to update,
                delete, or receive any information we have about you, you may do so by contacting
                the organization of which you are a customer.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Personnel </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={3}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                If you are one of our workers or applicants, we collect information you voluntarily
                provide to us. We use the information collected for Human Resources purposes in
                order to administer benefits to workers and screen applicants. You may contact us in
                order to
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                (1) update or correct your information,
              </Typography>
              <br />
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                (2) change your preferences with respect to communications and other information you
                receive from us, or
              </Typography>
              <br />
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                (3) receive a record of the information we have relating to you. Such updates,
                corrections, changes and deletions will have no effect on other information that we
                maintain, or information that we have provided to third parties in accordance with
                this Privacy Policy prior to such update, correction, change or deletion.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Sale of Business</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={3}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We reserve the right to transfer information to a third party in the event of a
                sale, merger or other transfer of all or substantially all of the assets of us or
                any of its Corporate Affiliates (as defined herein), or that portion of us or any of
                its Corporate Affiliates to which the Service relates, or in the event that we
                discontinue our business or file a petition or have filed against us a petition in
                bankruptcy, reorganization or similar proceeding, provided that the third party
                agrees to adhere to the terms of this Privacy Policy.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Affiliates</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We may disclose information (including personal information) about you to our
                Corporate Affiliates. For purposes of this Privacy Policy, "Corporate Affiliate"
                means any person or entity which directly or indirectly controls, is controlled by
                or is under common control with us, whether by ownership or otherwise. Any
                information relating to you that we provide to our Corporate Affiliates will be
                treated by those Corporate Affiliates in accordance with the terms of this Privacy
                Policy
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">How Long Do We Keep Your Information?</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We keep your information only so long as we need it to provide service to you and
                fulfil the purposes described in this policy. This is also the case for anyone that
                we share your information with and who carries out services on our behalf. When we
                no longer need to use your information and there is no need for us to keep it to
                comply with our legal or regulatory obligations, we'll either remove it from our
                systems or depersonalize it so that we can't identify you.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">How Do We Protect Your Information?</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We implement a variety of security measures to maintain the safety of your personal
                information when you place an order or enter, submit, or access your personal
                information. We offer the use of a secure server. All supplied sensitive/credit
                information is transmitted via Secure Socket Layer (SSL) technology and then
                encrypted into our Payment gateway providers database only to be accessible by those
                authorized with special access rights to such systems, and are required to keep the
                information confidential. After a transaction, your private information (credit
                cards, social security numbers, financials, etc.) is never kept on file. We cannot,
                however, ensure or warrant the absolute security of any information you transmit to
                us or guarantee that your information on the Service may not be accessed, disclosed,
                altered, or destroyed by a breach of any of our physical, technical, or managerial
                safeguards
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Governing Law</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                The laws of Kauzariyya, excluding its conflicts of law rules, shall govern this
                Agreement and your use of our service. Your use of our service may also be subject
                to other local, state, national, or international laws.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Your Consent</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                By using our service, registering an account, or making a purchase, you consent to
                this Privacy Policy
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Links to Other Websites</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                This Privacy Policy applies only to the Services. The Services may contain links to
                other websites not operated or controlled by DARUL IFTA KAUZARIYYA. We are not
                responsible for the content, accuracy or opinions expressed in such websites, and
                such websites are not investigated, monitored or checked for accuracy or
                completeness by us. Please remember that when you use a link to go from the Services
                to another website, our Privacy Policy is no longer in effect. You’re browsing and
                interaction on any other website, including those that have a link on our platform,
                is subject to that website's own rules and policies. Such third parties may use
                their own cookies or other methods to collect information about you.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Cookies</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We use "Cookies" to identify the areas of our website that you have visited. A
                Cookie is a small piece of data stored on your computer or mobile device by your web
                browser. We use cookies to personalize the Content that you see on our website. Most
                web browsers can be set to disable the use of cookies. However, if you disable
                Cookies, you may not be able to access functionality on our website correctly or at
                all. We never place Personally Identifiable Information in Cookies.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Payment Details</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                In respect to any credit card or other payment processing details you have provided
                us, we commit that this confidential information will be stored in the most secure
                manner possible.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Kids' Privacy</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We collect information from kids under the age of 13 just to better our services. If
                you are a parent or guardian and You are aware that your child has provided Us with
                Personal Data without your permission, please contact Us. If We become aware that we
                have collected Personal Data from anyone under the age of 13 without verification of
                parental consent, we take steps to remove that information from our servers.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Changes To Our Privacy Policy</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                If we decide to change our privacy policy, we will post those changes on this page,
                and/or update the Privacy Policy modification date below.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Third-Party Services</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={3}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                We may display, include or make available third-party content (including data,
                information, applications and other products services) or provide links to
                third-party websites or services ("Third-Party Services").
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={3}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                You acknowledge and agree that we shall not be responsible for any Third-Party
                Services, including their accuracy, completeness, timeliness, validity, copyright
                compliance, legality, decency, quality or any other aspect thereof. We do not assume
                and shall not have any liability or responsibility to you or any other person or
                entity for any Third-Party Services.
              </Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                Third-Party Services and links thereto are provided solely as a convenience to you
                and you access and use them entirely at your own risk and subject to such third
                parties’ terms and conditions.
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Facebook Pixel</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                Facebook pixel is an analytics tool that allows you to measure the effectiveness of
                your advertising by understanding the actions people take on your website. You can
                use the pixel to: Make sure your ads are shown to the right people. Facebook pixel
                may collect information from your device when you use the service. Facebook pixel
                collects information that is held in accordance with its Privacy Policy
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={12} marginTop={4}>
          <Paper elevation={2} className="p-4">
            <Grid item md={12} marginTop={3}>
              <Typography variant="h5">Tracking Technologies</Typography>
            </Grid>
            <Grid item md={12} marginTop={1} marginBottom={4}>
              <ul>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Google Maps APIs Google Maps API is a robust tool that can be used to create a
                    custom map, a searchable map, check-in functions, display live data synching
                    with location, plan routes, or create a mashup just to name a fewest Google Maps
                    API may collect information from You and from Your Device for security purposes
                    Google Maps API collects information that is held in accordance with its Privacy
                    Polycystic
                  </Typography>
                </li>
                <li>
                  <Typography variant="paragraph" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
                    Cookies We use cookies to enhance the performance and functionality of our
                    service but are non-essential to their use. However, without these cookies,
                    certain functionality like videos may become unavailable or you would be
                    required to enter your login details every time you visit our service as we
                    would not be able to remember that you had logged in previously.
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Paper>
        </Grid>
      </Container>
      <FooterComponent />
    </div>
  );
}
