export default {
  user: {
    home: '/',
    login: '/login',
    register: 'register',
    resetpassword: '/resetpassword',
    about: '/about',
    contact: '/contact',
    HeaderNav: 'navbar',
    askFatwas: '/ask-fatwas',
    fatwas: '/fatwas',
    accountUser: '/user',
    accountUserHome: '/user/home',
    accountUserProfile: '/user/profile',
    accountAskQuestion: '/user/ask-question',
    accountUserAsk: '/user/ask',
    fatwasDetailsPage: '/answer-details',
    rulesandregulations: 'rulesandregulations',
    privacypolicy: '/privacypolicy',
    termsandconditions: '/termsandconditions'
  },
  admin: {
    admin: '/admin',
    adminlogin: '/admin/login',
    adminfatwas: 'fatwas',
    musthafthies: 'musthafthies',
    user: 'user',
    userDetails: 'user-details',
    mufthiAndStudent: 'mufthiandstudent',
    addMufthiAndStudent: 'addMufthiAndStudent',
    category: 'category',
    categoryDetails: 'category-details',
    article: 'article',
    addArticle: 'addArticle',
    articleDetails: 'article-details',
    addCategories: 'addCategories',
    addMufthi: 'addMufthi',
    addUser: 'addUser',
    fatwasDetails: 'fatwa-details',
    mufthiDetails: 'mufthi-details',
    mufthiAndStudentDetails: 'mufthiAndStudent-details',
    dashboard: 'dashboard',
    profile: 'profile'
  }
};
