import {
  ADD_USER_LOGIN_DETAILS,
  ADD_GENERAL_DETAILS,
  ADD_HOME_FILTER,
  HANDLE_TRIGGER_API_CALLS
} from '../types';

export const addUserLoginDetails = (data) => ({
  type: ADD_USER_LOGIN_DETAILS,
  data
});

export const addGeneralDetails = (data) => ({
  type: ADD_GENERAL_DETAILS,
  data
});

export const addHomeFilter = (data) => ({
  type: ADD_HOME_FILTER,
  data
});

export const triggerApiCallStatus = (data) => ({
  type: HANDLE_TRIGGER_API_CALLS,
  data
});
