import React, { useState } from 'react';
import axios from 'axios';
// import GoogleLogin from "react-google-login";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import { TextField, InputAdornment, IconButton, Grid, Paper, Hidden } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import Avatar from '@mui/material/Avatar';
import { URLS } from '../../../config/urls.config';
import BackgroundImage from '../../../assets/login_bg.jpg';

import '../../../styles/common.styles.scss';
import './reset.pwd.styles.scss';
import { useNavigate } from 'react-router-dom';
import routerList from '../../../routes/routerList';
import { StoreLocal } from '../../../utils/localStore';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Loader from '../../../components/common/Loader';
import { toast } from 'react-toastify';
import { addUserLoginDetails } from '../../../redux/actions';

function ResetPasswordPage(props) {
  const navigate = useNavigate();
  const [screens, setScreens] = useState('email');
  const [isVisible, setVisible] = useState(false);
  const [isCVisible, setCVisible] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [otp, setOtp] = useState([]);
  const [pwd, setPwd] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  // const setError = (value) => {
  //   setErrorPopup(value);
  //   setTimeout(() => {
  //     setErrorPopup({
  //       visible: false,
  //       message: "",
  //       type: "error",
  //       title: "",
  //     });
  //   }, 3500);
  // };

  const handleContinue = ({ email }) => {
    setLoader(true);
    axios
      .post(
        `${URLS.user}${URLS.sendOtp}`,
        { email },
        {
          'Content-Type': 'application/json'
        }
      )
      .then((res) => {
        console.log('res email', res);
        setLoader(false);
        if (res?.success) {
          toast(res.message, {
            onClose: () => {
              setScreens('otp');
            }
          });
        } else {
          toast(res.message, {
            onClose: () => {
              setLoader(false);
            }
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        toast('Somthing went wrong, please try again later', {
          onClose: () => {
            setLoader(false);
          }
        });
        console.log('error login', err);
      });
  };

  const handleVerify = ({ email, otp }) => {
    setLoader(true);
    axios
      .post(
        `${URLS.user}${URLS.verifyOtp}`,
        { email, otp },
        {
          'Content-Type': 'application/json'
        }
      )
      .then((res) => {
        setLoader(false);
        if (res?.success) {
          toast(res.message, {
            onClose: () => {
              setLoader(false);
              setScreens('password');
            }
          });
        } else {
          toast(res.message, {
            onClose: () => {
              setLoader(false);
            }
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        toast('Something went wrong, please try again later', {
          onClose: () => {
            setLoader(false);
          }
        });
        console.log('error login', err);
      });
  };
  const handleReset = ({ email, password, cpassword }) => {
    setLoader(true);
    axios
      .post(
        `${URLS.user}${URLS.resetPassword}`,
        { email, newPassword: password, confirmPassword: cpassword },
        {
          'Content-Type': 'application/json'
        }
      )
      .then((data) => {
        setLoader(false);
        console.log('res login', data);
        if (data.success && data.data) {
          toast(data.message, {
            onClose: () => {
              setLoader(false);
              navigate(`${routerList.user.login}`);
            }
          });
        } else {
          toast(data.message, {
            onClose: () => {
              setLoader(false);
            }
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        toast('Something went wrong, please try again later', {
          onClose: () => {
            setLoader(false);
          }
        });
        console.log('error login', err);
      });
  };

  return (
    <section className="login-section" style={{ backgroundImage: `url(${BackgroundImage})` }}>
      <div
        className="container login-container"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
        <Grid spacing={2} className="formWraper">
          <Grid item md={5} xs={5} lg={5} className="welcome-section">
            {/* <div className="welcomeDiv"> <h2>Welcome Back!</h2> </div> */}
          </Grid>

          <Grid item md={7} sm={7} lg={7} className="main-div d-flex justify-content-center">
            <Grid item md={12} className="login-form" sx={{ width: '370px', height: '450px' }}>
              <Paper elevation={2} className="formDiv p-4" sx={{ width: '100%', height: '100%' }}>
                <h2>Reset Password</h2>

                {screens === 'email' ? (
                  <form onSubmit={handleSubmit(handleContinue)}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Enter your email"
                      variant="standard"
                      className="email"
                      {...register('email', {
                        required: 'Email ID is required',
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: 'Invalid email Id ( eg: example@mail.com ) '
                        }
                      })}
                    />
                    <div className="error">{errors?.email?.message}</div>
                    <div className="signin-btn">
                      {isLoading ? (
                        <Loader skeleton layers={1} />
                      ) : (
                        <button className="btn" type="submit">
                          Continue
                        </button>
                      )}
                    </div>
                  </form>
                ) : screens === 'otp' ? (
                  <form onSubmit={handleSubmit(handleVerify)}>
                    <div className="password-row">
                      <div className="col-md-10">
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Enter OTP"
                          variant="standard"
                          className="email"
                          type={isVisible ? 'text' : 'otp'}
                          {...register('otp', {
                            required: 'OTP is required'
                          })}
                        />
                        <div className="error">{errors?.otp?.message}</div>
                      </div>
                    </div>
                    <div className="signin-btn">
                      {!isLoading && (
                        <button className="btn " type="submit">
                          Verify
                        </button>
                      )}
                    </div>
                  </form>
                ) : (
                  <form onSubmit={handleSubmit(handleReset)}>
                    <div className="password-row">
                      <div className="col-md-10">
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="New Password"
                          variant="standard"
                          className="email"
                          value={pwd}
                          onChange={(e) => setPwd(e.target.value)}
                          type={isVisible ? 'text' : 'password'}
                          {...register('password', {
                            required: 'New Password is required',
                            minLength: {
                              value: 8,
                              message: 'Minimum 8 character'
                            }
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setVisible(!isVisible)} edge="end">
                                  {!isVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        <div className="error">{errors?.password?.message}</div>
                      </div>
                    </div>
                    <div className="password-row">
                      <div className="col-md-10">
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Confirm Password"
                          variant="standard"
                          className="email"
                          type={isCVisible ? 'text' : 'password'}
                          {...register('cpassword', {
                            required: 'Confirm Password is required',
                            minLength: {
                              value: 8,
                              message: 'Minimum 8 character'
                            }
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setCVisible(!isCVisible)} edge="end">
                                  {!isCVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        <div className="error">{errors?.cpassword?.message}</div>
                      </div>
                    </div>
                    <div className="signin-btn">
                      {!isLoading && (
                        <button className="btn " type="submit">
                          Reset Password
                        </button>
                      )}
                    </div>
                  </form>
                )}

                <div className="back-btn">
                  <a className="text" onClick={() => navigate('/')}>
                    Back to home
                  </a>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  ...state
});
const mapDispatchToProps = (dispatch) => ({
  addUserLoginDetails: (payload) => dispatch(addUserLoginDetails(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
