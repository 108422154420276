import { Outlet, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import DashboardPage from '../pages/admin/dashsboard/mainContainer';
import RouterList from './routerList';

function PrivateRouting(props) {
  if (props.userLoginDetails?.user_type === 'Super Admin') {
    return (
      <DashboardPage>
        <Outlet />
      </DashboardPage>
    );
  }
  return <Navigate to={`${RouterList.admin.adminlogin}`} />;
}
const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps)(PrivateRouting);
