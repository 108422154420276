export const URLS = {
  login: '/login',
  user: '/user',
  sendOtp: '/sendOtp',
  verifyOtp: '/verifyOtp',
  resetPassword: '/resetPassword',
  category: '/category',
  madhab: '/madhab',
  subcategories: '/subcategories',
  questions: '/question',
  signup: '/signup',
  googleAuth: '/googleAuth',
  question: '/question',
  profile_pic: '/profile_pic',
  generals: '/generals',
  article: '/article',
  subcategory: '/subcategory',
  visitors: '/visitors',
  pdf: '/pdf'
};
